import * as React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { Router, Link } from '@reach/router'
import Drawer from '@material-ui/core/Drawer'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import ViewQuiltIcon from '@material-ui/icons/ViewQuilt'
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt'
import LogoutIcon from '@material-ui/icons/ExitToApp'
import AccountIcon from '@material-ui/icons/AccountCircle'
import DescriptionIcon from '@material-ui/icons/Description'
import MailIcon from '@material-ui/icons/Mail'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Divider from '@material-ui/core/Divider'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import useUser from 'hooks/useUser'
import useOrganizer from 'hooks/useOrganizer'
import useExpo from 'hooks/useExpo'

import NotFoundPage from 'routes/NotFound'
import SignInPage from 'routes/Account'

const ExhibitorsRoute = React.lazy(() => import('routes/Exhibitors'))
const AccountRoute = React.lazy(() => import('routes/Account'))
const InvoiceRoute = React.lazy(() => import('routes/Invoice'))
const MailRoute = React.lazy(() => import('routes/Mail'))
const ExportsRoute = React.lazy(() => import('routes/Exports'))
const IndexRoute = React.lazy(() => import('routes/Index'))

const drawerWidth = 230

export default function App() {
  const [user, auth] = useUser()
  const classes = useStyles()
  const [organizer] = useOrganizer()
  const [expo,_,setExpoIndex] = useExpo()
  const signOut = () => {
    auth.signOut()
    setTimeout(() => document.location.reload(), 100)
  }

  if (!user) {
    return (
      <Router>
        <SignInPage path='*' />
      </Router>
    )
  }
  
  return (
    <div className={classes.root}>
      {/** HEADER */}
      <CssBaseline />
      <AppBar position='fixed' className={classes.appBar}>
        <Toolbar classes={{
          root:classes.toolbarRoot
        }}>
          <Typography variant='h6' noWrap>
            {organizer.name} &bull; Messetool
          </Typography>
          {organizer.expos.length > 1 && (
            <Select
              labelId="expo-select"
              id="expo-select"
              value={expo.id}
              onChange={id => setExpoIndex(organizer.expos.findIndex(o => o.id===id.target.value))}
              className={classes.selectRoot}
              classes={{
                root: classes.select,
                icon: classes.select
              }}
            >
              {organizer.expos.map(({id}, i) => (
                <MenuItem key={id} value={id}>{id}</MenuItem>
              ))}
            </Select>
          )}
        </Toolbar>
      </AppBar>

      {/** DRAWER */}
      <Drawer
        className={classes.drawer}
        variant='permanent'
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <div className={classes.toolbar} />
        <List>
          {/*
          <ListItem component={Link} to='/' button>
            <ListItemIcon>
              <ViewQuiltIcon />
            </ListItemIcon>
            <ListItemText primary='Übersicht' />
          </ListItem>
          */}
          <ListItem component={Link} to='/exhibitors' button>
            <ListItemIcon>
              <SupervisorAccountIcon />
            </ListItemIcon>
            <ListItemText primary='Aussteller' />
          </ListItem>
          <ListItem component={Link} to='/exports' button>
            <ListItemIcon>
              <SystemUpdateAltIcon />
            </ListItemIcon>
            <ListItemText primary='Exporte' />
          </ListItem>
          <Divider />
          <ListItem component={Link} to='/account' button>
            <ListItemIcon>
              <AccountIcon />
            </ListItemIcon>
            <ListItemText primary='Account' />
          </ListItem>
          <ListItem
            component={Link}
            to='/invoicedata'
            button
            className={classes.nested}
          >
            <ListItemIcon>
              <DescriptionIcon />
            </ListItemIcon>
            <ListItemText primary='Rechnungsdaten' />
          </ListItem>
          <ListItem
            component={Link}
            to='/maildata'
            button
            className={classes.nested}
          >
            <ListItemIcon>
              <MailIcon />
            </ListItemIcon>
            <ListItemText primary='Mail-Optionen' />
          </ListItem>
          <ListItem button onClick={() => signOut()}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary='Logout' />
          </ListItem>
        </List>
      </Drawer>

      {/** ROUTES */}
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <React.Suspense fallback={null}>
          <Router>
            <IndexRoute path='/' />
            <ExhibitorsRoute path='/exhibitors' />
            <AccountRoute path='/account' />
            <InvoiceRoute path='/invoicedata' />
            <MailRoute path='/maildata' />
            <ExportsRoute path='/exports' />
            <NotFoundPage path='*' />
          </Router>
        </React.Suspense>
      </main>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  toolbarRoot: {
    justifyContent: 'space-between'
  },
  selectRoot: {
    borderBottom: '1px solid white',
    '&::before': {
      display: 'none'
    }
  },
  select: {
    color: 'white',
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3)
  },
  nested: {
    paddingLeft: theme.spacing(4)
  }
}))
