// @flow
import * as React from 'react'
import firebase from 'firebase/app'

const Context = React.createContext()

export function UserProvider ({children}) {
  const [ctx, setCtx] = React.useState([true, null, null])

  React.useEffect(() => {
    const auth = firebase.auth()
    return auth.onAuthStateChanged(user => {
      if(!user) {
        setCtx([false, user, auth])
      }
      else {
        firebase.firestore().doc(`users/${user.uid}`).onSnapshot(doc => {
          const user = doc.data()
          setCtx([false, user, auth])
        })
      }
    })
  }, [])

  const [isFetching, user, auth] = ctx

  return (
    <Context.Provider value={[user, auth]}>
      {isFetching || children}
    </Context.Provider>
  )
}

export default function useUser () {
  return React.useContext(Context)
}