import React, { useState } from 'react'
import useUser from 'hooks/useUser'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import LockOutlinedIcon from '@material-ui/icons/VpnKey'
import CssBaseline from '@material-ui/core/CssBaseline'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'

export default function PasswordChangePage() {
  const [, auth] = useUser(),
    [password1, setPassword1] = useState(''),
    [password2, setPassword2] = useState(''),
    [showPassword, setShowPassword] = useState(false),
    [error, setError] = useState(''),
    classes = useStyles(),
    isInvalid = password1 !== password2 || password1 === '',
    handleClickShowPassword = () => {
      setShowPassword(!showPassword)
    },
    handleMouseDownPassword = e => {
      e.preventDefault()
    },
    handleSubmit = e => {
      e.preventDefault()
      auth.currentUser.updatePassword(password1).catch(error => {
        setError(error)
      })
    }

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component='h1' variant='h5'>
          Passwort ändern
        </Typography>
        <form onSubmit={handleSubmit} className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoComplete='password'
                name='passwordOne'
                variant='outlined'
                required
                fullWidth
                label='Neues Passwort'
                onChange={e => setPassword1(e.target.value)}
                value={password1}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        edge='end'
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoComplete='password'
                name='passwordTwo'
                variant='outlined'
                required
                fullWidth
                label='Neues Passwort bestätigen'
                onChange={e => setPassword2(e.target.value)}
                value={password2}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        edge='end'
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type='submit'
                fullWidth
                variant='contained'
                color='primary'
                disabled={isInvalid}
                className={classes.submit}
              >
                Passwort ändern
              </Button>
            </Grid>
          </Grid>
          {error && <p>{error.message}</p>}
        </form>
      </div>
    </Container>
  )
}

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(2)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}))
