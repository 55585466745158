// @flow
import * as React from 'react'
import useOrganizer from 'hooks/useOrganizer'
import useFirestore from 'hooks/useFirestore'
import useUser from 'hooks/useUser'

const Context = React.createContext()

export function ExpoProvider ({children}) {
  const [organizer] = useOrganizer()
  const [user] = useUser()
  const [index, setIndex] = React.useState(0)

  if(organizer && !organizer.expos[index]) {
    throw new Error('no expo found. Maybe you forgot to add it to "/organizers/$organizerId -> expos"')
  }

  const [expo, isFetching, ref] = useFirestore(organizer ? `organizers/${organizer.id}/expos/${organizer.expos[index].id}` : '')

  return (
    <Context.Provider value={[expo, ref, setIndex]}>
      {(expo || !user) && children}
    </Context.Provider>
  )
}


export default function useExpo () {
  return React.useContext(Context)
}