import React, { useState } from 'react'
import useUser from 'hooks/useUser'
import { Link } from '@reach/router'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
// import { htmlToDOM } from 'html-react-parser'
// import { borderRadius } from '@material-ui/system'
// import shadows from '@material-ui/core/styles/shadows'

export default function SignInPage() {
  const [, auth] = useUser(),
    [email, setEmail] = useState(''),
    [password, setPassword] = useState(''),
    [error, setError] = useState(''),
    classes = useStyles(),
    isInvalid = password === '' || email === '',
    handleSubmit = e => {
      e.preventDefault()
      auth.signInWithEmailAndPassword(email, password).catch(error => {
        setError(error)
      })
    }

  return (
    <Container component='main' maxWidth='xs' className={classes.LoginScreen}>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component='h1' variant='h5'>
          Messetools Login
        </Typography>
        <form onSubmit={handleSubmit} className={classes.form} noValidate>
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            id='email'
            label='eMail-Adresse'
            name='email'
            onChange={e => setEmail(e.target.value)}
            autoComplete='email'
            value={email}
          />
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            name='password'
            label='Password'
            type='password'
            id='password'
            onChange={e => setPassword(e.target.value)}
            value={password}
            autoComplete='current-password'
          />
          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            disabled={isInvalid}
            className={classes.submit}
          >
            Einloggen
          </Button>

          {error && <p>{error.message}</p>}
        </form>

        <Link to='/pw-forget' className={classes.pwForgett}>Passwort vergessen</Link>
      </div>
    </Container>
  )
}

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundImage: "url('https://images.unsplash.com/photo-1437422061949-f6efbde0a471?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2550&q=80')",
      backgroundSize: 'cover',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%' 
    },
    html:{
      height: '100%'
    }
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '#325297'
  },
  LoginScreen: {
    backgroundColor: '#ffffff',
    borderRadius: '5px',
    paddingTop: '32px',
    paddingBottom: '32px',
    boxShadow: '1px 1px 2px #737373;'
  },

  pwForgett:{
    textDecoration: 'none',
    color: '#000000'

  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}))
