// @flow
import * as React from 'react'

import AccountPage from 'widgets/Account'

export default function Account() {
  return (
    <div>
      <AccountPage />
    </div>
  )
}
