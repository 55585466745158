import "babel-polyfill"
import React from 'react'
import ReactDOM from 'react-dom'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import App from 'containers/App'

firebase.initializeApp({
  apiKey: "AIzaSyAyRZIGAYBAOICmgS9IIAmKOpb9DPWydXo",
  authDomain: "messetool-pmta.firebaseapp.com",
  databaseURL: "https://messetool-pmta.firebaseio.com",
  projectId: "messetool-pmta",
  storageBucket: "messetool-pmta.appspot.com",
  messagingSenderId: "179962393703",
  appId: "1:179962393703:web:3258086aaad0a0f06410c2"
})

ReactDOM.render(<App />, document.getElementById('root'))
