import React, { useState } from 'react'
import { Link } from '@reach/router'
import useUser from 'hooks/useUser'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'

export default function PasswordForgetPage() {
  const [, auth] = useUser(),
    [email, setEmail] = useState(''),
    [error, setError] = useState(''),
    classes = useStyles(),
    isInvalid = email === '',
    handleSubmit = e => {
      e.preventDefault()
      auth.sendPasswordResetEmail(email).catch(error => {
        setError(error)
      })
    }

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component='h1' variant='h5'>
          Passwort vergessen
        </Typography>

        <form onSubmit={handleSubmit} className={classes.form} noValidate>
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            id='email'
            label='eMail-Adresse'
            name='email'
            onChange={e => setEmail(e.target.value)}
            autoComplete='email'
            value={email}
          />
          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            disabled={isInvalid}
            className={classes.submit}
          >
            Passwort zurücksetzen
          </Button>
          {error && <p>{error.message}</p>}
        </form>

        <Link to='/'>Zurück zum Login</Link>
      </div>
    </Container>
  )
}

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}))
