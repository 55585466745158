import React from 'react'
import useUser from 'hooks/useUser'
import SignInPage from './SignIn'
import PasswordForgetPage from './PasswordForget'
import PasswordChangePage from './PasswordChange'
import { Router } from '@reach/router'

export default function Account() {
  const [user] = useUser()

  if (!user) {
    return (
      <Router>
        <SignInPage path='*' />
        <PasswordForgetPage path='/pw-forget' />
      </Router>
    )
  } else {
    return <PasswordChangePage />
  }
}
