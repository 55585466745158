import * as React from 'react'
import Layout from './Layout'
import {UserProvider} from 'hooks/useUser'
import {OrganizerProvider} from 'hooks/useOrganizer'
import {ExpoProvider} from 'hooks/useExpo'

const MemoLayout = React.memo(Layout)

export default function App () {
  return (
    <UserProvider>
      <OrganizerProvider>
        <ExpoProvider>
          <MemoLayout />
        </ExpoProvider>
      </OrganizerProvider>
    </UserProvider>
  )
}