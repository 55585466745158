// @flow
import * as React from 'react'
import useUser from 'hooks/useUser'
import useFirestore from './useFirestore'

const Context = React.createContext()


export function OrganizerProvider ({children}) {
  const [user] = useUser()

  const organizerId = user ? user.organizerId : null // read from user token
  const [organizer, isFetching, ref] = useFirestore(user ? 'organizers/'+organizerId : '')

  if(!user) return (
    <Context.Provider value={[organizer, ref]}>
      {children}
    </Context.Provider>
  )

  return (
    <Context.Provider value={[organizer, ref]}>
      {isFetching || children}
    </Context.Provider>
  )
}

export default function useOrganizer () {
  return React.useContext(Context)
}